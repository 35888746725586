<template>
  <div class="order-item">
    <div :class="(isUnfold ? 'activeHeight' : '') + ' ' +  'cotainer'">
      <div class="addr-item" v-for="(item, index) in list" :key="index">
        <div :class="(activeIndex === index ? 'active' : '') + ' ' + 'radio-custom'" @click="selectAddress(index, item)"></div>
        <div class="name">{{ item.name }}</div>
        <div class="address">{{ item.city + '  ' +  item.adress }}</div>
        <div class="phone">{{ item.mobile }}</div>
        <div class="operate">
          <span @click="operate('edit', item, index)">编辑</span>
          <span @click="operate('delete', item, index)">删除</span>
        </div>
      </div>
    </div>
    <div class="unfold" @click="unfold">
      
      {{isUnfold ? '展开所有' : '收起所有'}}
      <img :class="!isUnfold ? 'is' : ''" src="@/assets/images/confirmOrder/unfold.png" alt="" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
export default {
  name: "OrderItem",
  props: {
    list: {
      type: Array,
      default() {
        return [];
      }
    }
  },

  data() {
    return {
      activeIndex: 0,
      isUnfold: false
    }
  },

  watch: {
    list: {
      handler(list) {
        let toId = this.orderInfo.to[0].id
        this.$store.dispatch('order/orderAffirm', {'key': 'takeoverid', 'value':toId})
        list.map((val, index) => {
          if(val.id === toId) {
            this.activeIndex = index
          }
        })
      },
      immediate: true
    }
  },

  computed: {
    ...mapState({
      orderInfo: state => state.order.orderInfo
    })
  },

  methods: {
    operate(type, item, index) {
      this.$emit("operate", type, item, index);
    },

    selectAddress(index, data) {
      this.activeIndex = index
      this.$store.dispatch('order/orderAffirm', {'key': 'takeoverid', 'value': data.id})
    },

    /**
     * @title 展开收起
     */
    unfold() {
      this.isUnfold = !this.isUnfold
    }
  }
};
</script>
<style lang="less" scoped>
.order-item {
  font-size: 14px; /* no */
  margin-top: 45px; /* no */
  .unfold {
    font-size: 14px; /* no */
    color: #999999;
    padding-left: 100px; /* no */
    cursor: pointer;
    display: flex;
    align-items: center;
    img {
      width: 14px; /* no */
      height: 6px; /* no */
      margin-left: 10px; /* no */
      transition: all 0.4s;
    }
    .is {
      transform: rotate(180deg);
    }
  }
  .radio-custom {
    width: 21px; /* no */
    height: 21px; /* no */
    border-radius: 50%;
    border: 1px solid #999999; /* no */
    cursor: pointer;

  }
  .active {
    border: 4px solid #921d22; /* no */
  }
  .name {
    padding-left: 20px; /* no */
    width: 120px; /* no */
    box-sizing: border-box;
  }
  .address {
    width: 375px;
  }
  .phone {
    flex: 1;
  }
  .operate {
    width: 120px; /* no */
    span {
      color: #931c22;
      cursor: pointer;
    }
    span:first-child {
      margin-right: 30px;
    }
  }
  .cotainer {
    transition: all 0.2s;
    // height: 106px; /* no */
    .addr-item {
      display: flex;
      height: 44px; /* no */
      align-items: center;
      color: #999999;
    }
  }
  .activeHeight {
    height: 46px; /* no */
    overflow: hidden;
  }
}
</style>
