<template>
  <div class="goods-container">
    <div class="header">
      <p>产品</p>
      <p>数量</p>
      <p>价格</p>
    </div>
    <div class="body">
      <div v-for="(item, index) in products" :key="index" class="list">
        <div class="list-d">
          <div class="image" :style="{ backgroundImage: `url(${item.product.pic.picUrl})` }"></div>
          <div class="info">
            <p class="title">{{ item.product.title }}</p>
            
            <p class="sku"><span v-if="item.product.drskudes">{{item.product.drskudes}}</span><span>{{ item.product.dskudes }}</span></p>
            
          </div>
        </div>
        <div class="list-d">x{{ item.product.pnum }}</div>
        <div class="list-d">￥{{ item.product.totalprice | amount }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    products: {
      type: Array,
      default: () => []
    }
  },

  filters: {
    amount(number) {
      return isNaN(number) ? 0.0 : parseFloat((number / 100).toFixed(2));
    }
  }
}
</script>

<style lang="less" scoped>
p, div {
  box-sizing: border-box;
}
.goods-container {
  flex: 1;
  margin-right: 50px; /* no */
  box-shadow: 1px 1px 10px #cfcfcf; /* no */
}
.header {
  width: 100%;
  height: 60px; /* no */
  display: flex;
  align-items: center;
  border-bottom: 1px solid #cfcfcf;  /* no */
  padding: 0 20px; /* no */
  p {
    font-size: 20px; /* no */
    color: #333333;
    width: 25%;
    text-align: center;
    &:nth-child(1) {
      width: 50%;
      text-align: left;
    }
  }
}

.body {
  width: 100%;
  padding: 0 20px; /* no */
  .list {
    display: flex;
    align-items: center;
    width: 100%;
    height: 174px; /* no */
    border-bottom: 1px solid #cfcfcf; /* no */
    &:last-child {
      border-bottom: none;
    }
    .list-d {
      width: 25%;
      text-align: center;
      font-size: 14px; /* no */
      color: #333333;
      &:nth-child(1) {
        width: 50%;
        text-align: left;
        display: flex;
        align-items: center;
        .image {
          width: 114px; /* no */
          height: 114px; /* no */
          box-shadow: 1px 1px 10px #cfcfcf; /* no */
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
        }
        .info {
          flex: 1;
          height: 100%;
          margin-left: 20px; /* no */
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .title {
            font-size: 16px; /* no */
            margin-bottom: 20px; /* no */
          }
          .sku {
            margin-bottom: 20px; /* no */
            span:last-child{
              padding-left: 5px;
            }
          }
          .sieze {

          }
        }
      }
    }
  }
}
</style>