<template>
  <div class="order-container" v-if="orderInfo">
    <p class="title">确认订单</p>
    <Address :initAddress="orderInfo.to[0]"/>
    <div class="content">
      <Goods :products="orderInfo.datas.products"/>
      <PayContent />
    </div>
  </div>
</template>

<script>
import Address from "./components/Address"
import Goods from "./components/Goods"
import PayContent from "./components/PayContent"
import { mapState } from "vuex";
import { ORDER_GETCOINFO } from "@/service/order";
export default {
  name: 'ConfirmOrder',
  components: {
    Address,
    Goods,
    PayContent
  },

  computed: {
    ...mapState({
      orderInfo (state) {
        
        if(state.order.orderInfo['datas']) {
          // console.log(1)
          // console.log(state.order.orderInfo['datas'])
          this.handleProductsData(state.order.orderInfo.datas.products)
          return state.order.orderInfo
        } else {          
          ORDER_GETCOINFO().then(res => {
            if(res.status !== 200) {
              this.$notify({
                title: '错误',
                message: res.message,
                type: 'error'
              });
              this.$router.go(-1)
            }
            this.handleProductsData(res.attachment.datas.products)
            this.$store.dispatch("order/orderInfo", res.attachment);
            return res.attachment
          })
        }
      }
    })
  },

  methods: {
    handleProductsData(data) {
      let propertis = {
        products: []
      }
      data.map(val => {
        propertis.products.push(val.product)
        console.log(val)
      })
      this.$store.dispatch('order/orderAffirm', {'key': 'propertis', 'value': JSON.stringify(propertis)})
    }
  }
}
</script>

<style lang="less" scoped>
.order-container {
  padding: 30px 360px; /* no */
  padding-bottom: 200px !important; /* no */
  .title {
    font-size: 30px; /* no */
    padding: 34px 0; /* no */
    border-bottom: 1px solid #cfcfcf; /* no */
  }
  .content {
    margin-top: 68px; /* no */
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 640px) {
  .order-container {
    padding: 30px 40px; /* no */
  }
}
@media only screen and (min-width: 640px) and (max-width: 960px) {
  .order-container {
    padding: 30px 40px; /* no */
  }
}
@media only screen and (min-width: 960px) and (max-width: 1200px) {
  .order-container {
    padding: 30px 160px; /* no */
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1700px) {
  .order-container {
    padding: 30px 200px; /* no */
  }
}

</style>