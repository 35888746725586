<template>
  <div class="address">
    <p class="title">收货地址:</p>
    <div class="add-address">
      <div class="add-text" @click="addAddress">
        <img src="@/assets/images/confirmOrder/addIcon.png" alt="">
        <p class="text">添加新的收货地址</p>
      </div>
      <address-list :list="addressList" @operate="operate" />
    </div>
    <addr-diolog :visiable.sync="addrDialog" :info="dialogAddr" @confirm="addrDiologConfirm" />
  </div>
</template>

<script>
import addrDiolog from "../../myCenter/components/addrDiolog";
import AddressList from "./AddressList";
import { GET_ADDRESSLIST, DEL_ADDRESS } from "@/service/address";
export default {
  components: {
    addrDiolog,
    AddressList
  },

  data() {
    return {
      addrDialog: false,
      dialogAddr: {},
      addressList: []
    }
  },

  created() {
    this.getAddrList();
  },

  methods: {
    addAddress() {
      this.addrDialog = true
    },
    addrDiologConfirm() {
      this.getAddrList();
    },

    getAddrList() {
      GET_ADDRESSLIST({ uid: 1 }).then(res => {
        this.addressList = res.attachment.to;
      });
    },

    /**
     * @title 编辑地址
     */
    editAddr(address) {
      this.addrDialog = true;
      this.dialogAddr = Object.assign({}, address);
    },

    /**
     * @title 删除地址
     */

    deleteAddr(address, index) {
      this.addressList.splice(index, 1);
      DEL_ADDRESS({ uid: 1, takeoverid: address.id }).then(res => {
        if (res.status === 200) {
          this.$message({
            type: "success",
            message: "删除成功"
          });
        }
      });
    },

    operate(...arg) {
      let type = arg[0];
      if (typeof this[type + "Addr"] === "function") {
        this[type + "Addr"](...arg.slice(1));
      }
    },
  }
}
</script>

<style lang="less" scoped>
.address {
  padding: 0 20px 44px 20px; /* no */
  border-bottom: 1px solid #cfcfcf; /* no */
  .title {
    font-size: 24px; /* no */
    color: #333333;
    margin: 55px 0 40px 0; /* no */
  }
  .add-address {
    padding-left: 60px; /* no */
    .add-text {
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        width: 24px; /* no */
        height: 24px; /* no */
      }
      .text {
        font-size: 20px; /* no */
        color: #921d22;
        margin-left: 7px; /* no */
      }
    }
  }
}

</style>