<template>
  <div class="b">
    <div class="pay-container">
      <p class="title">结算</p>
      <div class="body">
        <div class="list">
          <p>总计</p>
          <p>￥{{ payInfo.datas.totalfee | amount }}</p>
        </div>
        <div class="list">
          <p>尊享礼劵</p>
          <el-popover
            placement="bottom"
            width="380"
            trigger="click"
            popper-class="coupon-popover"
            @show="popoverShow"
          >
            <div class="coupon">
              <div class="coupon-title">
                可使用优惠券
              </div>
              <div class="coupon-body">
                <div v-for="(item, index) in coupons" :key="index" class="coupon-list">
                  <p class="coupon-list-price">{{ item.amountStr }}</p>
                  <div class="coupon-list-info">
                    <p>{{ item.c }}</p>
                    <p>{{ item.etStr }}</p>
                  </div>
                  <p :class="(item.useState ? 'active' : '') + ' ' + 'coupon-list-btn'" @click="clickUseCoupon(item, index)">
                    {{item.useState ? '已选择' : '使用'}}
                  </p>
                </div>
              </div>
            </div>
            <p class="coupon-btn"  slot="reference">
              -￥{{ payInfo.pushcmoney | amount }}
              <img src="@/assets/images/confirmOrder/aroow.png" alt="">
            </p>
          </el-popover>
          
        </div>
        <div class="list">
          <p>最后支付</p>
          <p>￥{{ payInfo.totalfee - payInfo.pushcmoney | amount }}</p>
        </div>
      </div>
    </div>
    <div class="handle">
      <p @click="orderConfirm">确认下单</p>
    </div>
  </div>
</template>

<script>
import { ORDER_AFFIRM } from "@/service/order";
import { mapState } from "vuex";
export default {

  filters: {
    amount(number) {
      return isNaN(number) ? 0.0 : parseFloat((number / 100).toFixed(2));
    }
  },

  data() {
    return {
      payInfo: {},
      coupons: []
    }
  },

  computed: {
    ...mapState({
      orderAffirmObj: (state) => state.order.orderAffirmObj,
      info: (state) => state.order.orderInfo
    })
  },

  watch: {
    info: {
      handler: function (val) {
        this.payInfo = JSON.parse(JSON.stringify(val))
        this.payInfo.coupons.map(val => {
          val.useState = this.payInfo.pushcids.includes(val.id)
        })
        this.coupons = JSON.parse(JSON.stringify(this.payInfo.coupons))
      },
      deep: true,
      immediate: true
    }
  },

  methods: {

    popoverShow() {
      let useCoupon = []
      let notCoupon = []
      this.coupons.map(val => {
        if(val.useState) {
          useCoupon.push(val)
        } else {
          notCoupon.push(val)
        }
      })
      this.coupons = useCoupon.concat(notCoupon)
    },

    goBack() {
      this.$router.go(-1)
    },

    /**
     * @title 下单
     */
    orderConfirm() {
      let cids = []
      this.coupons.map(val => {
        if(val.useState) {
          cids.push(val.id)
        }
      })
      ORDER_AFFIRM({f: 2, cids: cids.join(','), ...this.orderAffirmObj}).then(res => {
        if(res.status === 200) {
          // this.$notify({
          //   title: '成功',
          //   message: '下单成功',
          //   type: 'success'
          // });
           this.$store.commit("user/SETCAR", res.attachment.total);
          this.$store.dispatch('order/orderAffirmInfo', res.attachment)
          this.$router.push({
            path: '/confirmPay',
            query: {
              orderAffirmInfo: JSON.stringify(res.attachment),
              flag:1,
            }
          })
        }
      })
    },

    /**
     * @title 点击优惠券使用
     * @param { item } Object
     */
    clickUseCoupon(item, index) {
      let useArr = []
      let flagState = true
      let overlayState = true
      let clickUseCouponState = false
      // 首先判断当前列表有无使用优惠券
      this.coupons.map((val) => {
        if(val.useState) {
          useArr.push(val)
        }
      })

      // 如果有使用过的优惠券
      if(useArr.length > 0) {

        useArr.map((val, index) => {
          // 点击了已使用的优惠券
          if(val.id === item.id) {
            flagState = false
            overlayState = false
            val.useState = false
            clickUseCouponState = true
            useArr.splice(index, 1)
            return
          } else {
            // 点击了没有使用的优惠券 判断flag 是否相同
            if(val.flag === item.flag) {
              flagState = false
              return
            }
            if(val.overlayState === 0) {
              overlayState = false
              return
            }
          }
        })
        
        if(flagState && overlayState) {
          // 可以使用
          this.coupons[index].useState = true
          useArr.push(item)
        } else {
          if(!clickUseCouponState) {
            this.$message({
              type: "warning",
              message: "此优惠券不可混合使用"
            });
          }
        }

      } else {
        this.coupons[index].useState = true
        useArr.push(item)
      }

      // 处理数据
      this.handlePushcmoney(useArr)
    },

    handlePushcmoney(useArr) {
      // type 0 discount 折扣
      // type 1 deduction 抵扣
      let discount = []
      let deduction = []
      let totalfee = this.payInfo.totalfee
      useArr.map(val => {
        if(val.type === 0) {
          discount.push(val.amount/100)
        }
        if(val.type === 1) {
          deduction.push(val.amount)
        }
      })
      
      discount.map(val => {
        totalfee *= val
      })
      deduction.map(val => {
        totalfee -= val
      })

      this.payInfo.pushcmoney = this.payInfo.totalfee - totalfee
    }
  }
}
</script>
<style>
.coupon-popover.el-popper{
  border:1px solid#921d22 !important; /* no */
  height: 220px;
  overflow: scroll;
}
.coupon-popover.el-popper::-webkit-scrollbar{  
    width: 5px;  
    height:10px;     
}  
.coupon-popover.el-popper::-webkit-scrollbar-track{  
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);  
    border-radius: 10px; 
}




.coupon-popover.el-popper::-webkit-scrollbar-thumb{  
    border-radius: 10px;  
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);  
} 





.coupon-popover.el-popper[x-placement^=bottom] .popper__arrow {
  border-bottom-color: #921d22 !important; /* no */
}
.coupon-popover.el-popper[x-placement^=top] .popper__arrow {
  border-top-color: #921d22 !important; /* no */
 }
.coupon-popover.el-popper[x-placement^=right] .popper__arrow { 
  border-right-color: #921d22 !important; /* no */
}
.coupon-popover.el-popper[x-placement^=left] .popper__arrow { 
  border-left-color: #921d22 !important; /* no */
}
</style>
<style lang="less" scoped>
.pay-container {
  width: 380px;
  box-shadow: 1px 1px 10px #cfcfcf; /* no */
  height: fit-content;
  .title {
    display: flex;
    align-items: center;
    font-size: 20px; /* no */
    color: #333333;
    height: 60px; /* no */
    padding-left: 20px; /* no */
    border-bottom: 1px solid #cfcfcf;  /* no */
  }
  .body {
    padding: 28px 20px 3px 20px;  /* no */
    .list {
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px; /* no */
      p {
        font-size: 14px;
        &:nth-child(1) {
          color: #333333;
        }
        &:nth-child(2) {
          color: #666666;
        }
      }
      .coupon-btn {
        color: #666666;
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          width: 5px; /* no */
          margin-left: 12px; /* no */
        }
      }
      
    }
  }
}

.coupon {
  .coupon-title {
    font-size: 14px; /* no */
    color: #666666;
    height: 40px; /* no */
    line-height: 40px; /* no */
    border-bottom: 1px solid #cfcfcf; /* no */
  }
  .coupon-body {
    .coupon-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 10px 0 10px; /* no */
      .coupon-list-price {
        width: 60px; /* no */
        height: 26px; /* no */
        font-size: 12px; /* no */
        color: #921d22;
        border: 1px solid #921d22; /* no */
        text-align: center;
        line-height: 26px; /* no */
      }
      .coupon-list-info {
        p {
          color: #666666;
          font-size: 10px; /* no */
        }
      }
      .coupon-list-btn {
        width: 50px; /* no */
        height: 26px; /* no */
        border-radius: 5px; /* no */
        color: #FFFFFF;
        text-align: center;
        line-height: 26px; /* no */
        background: #921d22;
        cursor: pointer;
      }
      .active {
        color: #FFFFFF;
        background: #999999;
      }
    }
  }
}

.handle {
  display: flex;
  justify-content: center;
  margin-top: 20px; /* no */
  p {
    width: 264px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    background-color: #921d22;
    color: #ffffff;
    border-radius: 20px;
    box-shadow: 1px 1px 10px #cfcfcf; /* no */
    cursor: pointer;
  }
}
</style>